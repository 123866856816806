<template>
    <BackofficeBase :loader_prop="loader_prop">
      <body class="dash__back">
        <div class="overlay"></div>
        <div class="dashboard__wrapper no__spacer">
          <div class="dashboard__main no__spacer">
            <div class="new__worker">
              <div class="breadcrumbs">
                <ul>
                  <li>
                    <router-link :to="{ path: '/backoffice/dashbord' }"
                      >ניהול עובדים</router-link
                    >
                  </li>
                  <li class="spacer">></li>
                  <li class="current">עריכת עובד</li>
                </ul>
              </div>
              <div class="new__worker--form">
                <!-- <form action=""> -->
                <div class="sub__group">
                  <h6>פרטי העובד</h6>
                  <div class="group__input regular-input">
                    <p>שם פרטי</p>
                    <input type="text" v-model="first_name" />
                  </div>
                  <div class="group__input regular-input">
                    <p>שם משפחה</p>
                    <input type="text" v-model="last_name" />
                  </div>
                  <div class="group__input regular-input">
                    <p>ת”ז</p>
                    <input
                      type="text"
                      v-model="government_id"
                      @keydown="filterInput"
                    />
                  </div>
                  <div class="group__input regular-input">
                    <p>מספר טלפון</p>
                    <input type="text" v-model="phone" @keydown="filterInput" />
                  </div>
                  <div class="group__input regular-input">
                    <p>אימייל</p>
                    <input type="text" v-model="email" />
                  </div>
                </div>
                <div class="sub__group">
                  <h6>תנאי העובד</h6>
                  <div class="double__inputs">
                    <div class="group__input small">
                      <p>תשלום בסיס שעתי</p>
                      <input
                        type="number"
                        placeholder="₪"
                        v-model="hourly_wage"
                        min="0"
                      />
                    </div>
                    <div class="group__input small">
                      <p>תשלום נסיעות</p>
                      <input
                        type="number"
                        placeholder="₪"
                        v-model="travel_expenses"
                        min="0"
                      />
                    </div>
                  </div>
                </div>
                <div class="sub__group">
                  <h6>ותק</h6>
                  <div class="group__input">
                    <p>תקופת עבודה</p>
                  </div>
                  <div class="switcher">
                    <label class="switch">
                      <input
                        type="checkbox"
                        v-model="is_active"
                        @input="toggleCheckbox"
                      />
                      <span class="slider"></span>
                      <p>{{ is_active ? "עובד פעיל" : "עובד לא  פעיל" }}</p>
                    </label>
                  </div>
                  <div class="group__input medium">
                    <p>תאריך התחלה</p>
                    <input
                      type="date"
                      class="date__picker"
                      v-model="start_date_to_calender"
                    />
                  </div>
                  <div class="group__input medium" v-if="!is_active">
                    <p>תאריך סיום</p>
                    <input type="date" class="date__picker" v-model="end_date" />
                  </div>
                </div>
  
                <div v-if="err">
                  <p class="err_text">{{ err_text }}</p>
                </div>
                <br />
                <div class="worker__controls">
                  <button v-on:click="submit_from" class="regular-btn smaller">
                    שמירה
                  </button>
                  <a href="#" class="outline-btn">ביטול</a>
                </div>
                <!-- </form> -->
              </div>
            </div>
          </div>
        </div>
  
        <div class="modal__wrapper" style="display: none">
          <div class="inner__modal">
            <div class="modal__box export">
              <a href="#"><img src="../../assets/img/close.svg" alt="close" /></a>
              <form action="">
                <h2>הנפקת דו”ח</h2>
                <div class="group__input group__dropdown">
                  <p>בחירת חודש</p>
                  <a href="#"
                    ><span>מאי 2023</span>
                    <img src="../../assets/img/arrow.svg" alt="arrow"
                  /></a>
                  <div class="dropdown__box" style="display: none">
                    <ul>
                      <li><a href="#">1</a></li>
                      <li><a href="#">2</a></li>
                      <li><a href="#">3</a></li>
                      <li><a href="#">4</a></li>
                      <li><a href="#">5</a></li>
                      <li><a href="#">1</a></li>
                      <li><a href="#">2</a></li>
                      <li><a href="#">3</a></li>
                      <li><a href="#">4</a></li>
                      <li><a href="#">5</a></li>
                    </ul>
                  </div>
                </div>
                <div class="controls">
                  <button type="submit" class="regular-btn">הנפק דו”ח</button>
                  <a href="#" class="outline-btn cancel__button">ביטול</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </body>
    </BackofficeBase>
  </template>
  
  <script>
  import BackofficeBase from "./BackofficeBase";
  import BackendModel from "../../models/BackendModel";
  // import BackofficeSidebar from "./BackofficeSidebar";
  
  export default {
    name: "BackofficeNewEmploeey",
    components: {
      BackofficeBase,
      
    },
    data() {
      return {
        loader_prop: false,
        id: "0",
        first_name: "",
        last_name: "",
        government_id: "",
        phone: "",
        email: "",
        hourly_wage: 0,
        travel_expenses: 0,
        is_active: true,
        start_date: "",
        start_date_to_calender:'',
        end_date: "",
        err: false,
        err_text: "",
      };
    },
    async mounted() {
    this.id = this.$route.query.id    
    let backendModel = new BackendModel()
    let data = await backendModel.backendRequest("/Api/service/backoffice/get_employee",{"id" : this.id})
    // this.data = data.data.data.employee
    this.data = data.data.data.employee[0]
    this.first_name = this.data.first_name
    this.last_name = this.data.last_name
    this.government_id = this.data.government_id
    this.phone = this.data.phone
    this.email = this.data.email
    this.start_date = this.data.start_date
    this.hourly_wage = this.data.hourly_wage
    this.travel_expenses = this.data.travel_expenses
    this.is_active = this.data.is_active
    const new_startdate= new Date(this.start_date)
    const year = new_startdate.getFullYear().toString().padStart(4, '0');
    const month = (new_startdate.getMonth() + 1).toString().padStart(2, '0');
    const day = new_startdate.getDate().toString().padStart(2, '0');
    this.start_date_to_calender = `${year}-${month}-${day}`;
    // if( this.is_active =='1'){
    //     this.is_active =true
    // }else{
    //     this.is_active=false
    // }
    // this.calculateTimePassed = this.data.calculateTimePassed
    // this.start_date_to_show = this.data.start_date_to_show
	console.log(this.data)
    },
    methods: {
      toggleCheckbox() {
        this.is_active = !this.is_active;
        if (this.is_active) {
          this.end_date = "";
        }
      },
      filterInput(event) {
    const keyCode = event.keyCode;

    if (keyCode === 9) { // אם המקש הוא TAB
     return;
    }

    if (!(
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      keyCode === 8 || // BACKSPACE
      keyCode === 46  // DELETE
    )) {
      event.preventDefault();
    }
  },
      async submit_from(e) {
        e.preventDefault();
        let err_counter = 0;
        if (
          this.first_name == "" ||
          this.last_name == "" ||
          this.government_id == "" ||
          // this.phone == "" ||
          // this.email == "" ||
          this.hourly_wage == 0 ||
          this.start_date_to_calender == ""
        ) {
          err_counter++;
          this.err = true;
          this.err_text = "יש למלא את כל השדות";
        }
        if (this.is_active == false && this.end_date == "") {
          err_counter++;
          this.err = true;
          this.err_text = "אם העובד סיים את עבודתו יש להזין תאריך סיום";
        }
        // if (!/^[0-9]{1,10}$/.test(this.phone)) {
        //   err_counter++;
        //   this.err = true;
        //   this.err_text = "הזן מספר טלפון תקין";
        // }
        // if (!/^[a-zA-Z0-9_$]+@[a-zA-Z0-9_$]+\.[a-zA-Z]+$/.test(this.email)) {
        //   err_counter++;
        //   this.err = true;
        //   this.err_text = "הזן כתובת מייל תקינה";
        // }
      //   if (!/^\d{9}$/.test(this.goverment_id)) {
      //     err_counter++;
      //     this.err = true;
      //     this.err_text = "מספר תעודת הזהות אינו תקין";
      //   }
        if (!this.is_active) {
          let startDateObj = new Date(this.start_date_to_calender);
          let endDateObj = new Date(this.end_date);
          if (endDateObj < startDateObj) {
            err_counter++;
            this.err = true;
            this.err_text =
              "שים לב לתאריכים, עובד לא יכול לסיים את עבודתו לפני שהתחיל...";
          }
        }
        console.log(err_counter)
        if (err_counter == 0) {
          this.loader_prop = true;
          if (this.is_active == true) {
            this.is_active = "1";
          } else {
            this.is_active = "0";
          }
 
          let backendModel = new BackendModel()
          let params = {
              id: this.id ,
              first_name: this.first_name ,
              last_name: this.last_name ,
              government_id: this.government_id ,
              phone: this.phone ,
              email: this.email ,
              hourly_wage: this.hourly_wage ,
              travel_expenses: this.travel_expenses ,
              is_active: this.is_active ,
              start_date: this.start_date_to_calender ,
              end_date: this.end_date ,
          }
          let res = await backendModel.backendRequest("/Api/service/backoffice/save_employee", params)
          if(res.data.res =="OK"){
            this.loader_prop = false
            await this.$router.push({ path:  '/backoffice/dashbord'} )
        }else{
            this.err = true;
          this.err_text ="משהו לא תקין"
        }
       
        }
      },
    },
  };
  </script>
  <style>
  @import "../../assets/new_style.css";
  </style>
  